export const posts = [
    {
        "id": "1",
        "title": "5 Essential Tree Care Tips for Simi Valley Homeowners",
        "metaTitle": "Tree Care Tips for Homeowners in Simi Valley, CA | Simi Valley Tree Service Pros Blog",
        "excerpt": "Discover the top tree care practices to keep your landscape vibrant and healthy throughout the year.",
        "slug": "essential-tree-care-tips-for-simi-valley-homeowners",
        "tags": ["tree care", "tips", "homeowners", "Simi Valley"],
        "metaDescription": "Learn the best tree care tips for Simi Valley homeowners to ensure your trees remain healthy and beautiful all year round.",
        lastModified: new Date('2023-12-03').toISOString(),
        createdAt: new Date('2023-12-03').toISOString(),
    },
    {
        "id": "2",
        "title": "The Importance of Regular Tree Pruning",
        "metaTitle": "Why Regular Tree Pruning is Crucial for Tree Health | Simi Valley Tree Service Pros Blog",
        "excerpt": "Find out why regular tree pruning is not just about aesthetics but crucial for the health and safety of your trees.",
        "slug": "importance-of-regular-tree-pruning",
        "tags": ["tree pruning", "tree health", "safety"],
        "metaDescription": "Explore the benefits of regular tree pruning and how it contributes to the health, safety, and aesthetic appeal of your trees.",
        lastModified: new Date('2023-12-03').toISOString(),
        createdAt: new Date('2023-12-03').toISOString(),
    },
    {
        "id": "3",
        "title": "How to Deal with Tree Diseases in Simi Valley",
        "metaTitle": "Managing Tree Diseases in Simi Valley, CA | Simi Valley Tree Service Pros Blog",
        "excerpt": "Learn how to identify, treat, and prevent common tree diseases in Simi Valley to keep your trees thriving.",
        "slug": "dealing-with-tree-diseases-in-simi-valley",
        "tags": ["tree diseases", "treatment", "prevention", "Simi Valley"],
        "metaDescription": "A comprehensive guide to identifying, treating, and preventing tree diseases in Simi Valley, CA, to ensure the longevity of your trees.",
        lastModified: new Date('2023-12-03').toISOString(),
        createdAt: new Date('2023-12-03').toISOString(),
    },
    {
        "id": "4",
        "title": "The Benefits of Eco-Friendly Tree Care Practices",
        "metaTitle": "Eco-Friendly Tree Care Practices and Their Benefits | Simi Valley Tree Service Pros Blog",
        "excerpt": "Discover how adopting eco-friendly tree care practices can benefit not only your trees but the environment as well.",
        "slug": "eco-friendly-tree-care-practices",
        "tags": ["eco-friendly", "tree care", "environment"],
        "metaDescription": "Learn about the importance of eco-friendly tree care practices and how they contribute to a healthier environment and vibrant landscapes.",
        lastModified: new Date('2023-12-03').toISOString(),
        createdAt: new Date('2023-12-03').toISOString(),
    }
]

import React from "react";
import {Box, Stack} from "@chakra-ui/react";
import {SiteData} from "./siteData";

export const AdditionalContentSection = () => {
    return (
        <Box py={{base:10, md: 20}} mt={0} px={{base: 5, md: 0}}>
            <div className="main_wrapper">
                <div className="container">
                    <Stack spacing={3}>
                        {SiteData.homepage.additionalContent}
                    </Stack>
                </div>
            </div>
        </Box>
    )
}
import {
    Box,
    Button,
    Container,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    Text,
    Textarea,
    theme,
    useBreakpointValue
} from "@chakra-ui/react";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {EmailIcon, PhoneIcon} from "@chakra-ui/icons";
import {FaUserAlt} from "react-icons/fa";

export const SignUpForm = ({description, maxW}) => {
    const formRef = useRef(null);
    const [animate, setAnimate] = useState(false);
    const animationApplied = useRef(false);

    const checkVisibility = () => {
        if (formRef.current) {
            const rect = formRef.current.getBoundingClientRect();
            return rect.top >= 0 && rect.bottom <= window.innerHeight;
        }
        return false;
    };

    useEffect(() => {
        // Immediate check for visibility and apply animation if visible
        if (checkVisibility() && !animationApplied.current) {
            setAnimate(true);
            animationApplied.current = true; // Mark that animation has been applied
        }

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && !animationApplied.current) {
                        setAnimate(true);
                        animationApplied.current = true; // Mark that animation has been applied
                        observer.unobserve(formRef.current);
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1
            }
        );

        // Start observing
        if (formRef.current) {
            observer.observe(formRef.current);
        }

        // Cleanup
        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, []);

    return (
        <Box ref={formRef} className={animate ? 'animate__animated animate__slideInRight' : ''}>
            <Container mr={{base: 0, md: 20}} mb={{base: '3rem', md: 0}} p={'2rem'} background={'#f4f4f4'} >
                <Stack spacing="8">
                    <Stack spacing="6">
                        {/*<Logo />*/}
                        <Stack spacing="3">
                            <Heading size={useBreakpointValue({base: "md", md: "lg"})}>Get <Text color={'#64bcb8'}
                                                                                                 as="span">FREE</Text> estimate!</Heading>
                            <Text
                                color="muted">{description || 'Provide some information and get a quote today'}</Text>
                        </Stack>
                    </Stack>

                    <Stack spacing="6">
                        <form name="contact" method="POST" action={"/?index"} data-netlify="true">
                            <input type="hidden" name="form-name" value="contact"/>
                            <Stack spacing="4">
                                <FormControl isRequired>
                                    <FormLabel htmlFor="name">Name</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents='none'>
                                            <FaUserAlt style={{
                                                color: theme.colors.gray['300'],
                                                position: 'relative',
                                                top: '5px'
                                            }}/>
                                        </InputLeftElement>
                                        <Input id="name" type="text" name="name" style={{paddingLeft: '2rem'}}/>
                                    </InputGroup>
                                </FormControl>
                                <FormControl isRequired>
                                    <FormLabel htmlFor="email">Email</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents='none'>
                                            <EmailIcon color='gray.300' pos={'relative'} top={'5px'}/>
                                        </InputLeftElement>
                                        <Input id="email" type="email" name="email" style={{paddingLeft: '2rem'}}/>
                                    </InputGroup>
                                </FormControl>
                                <FormControl isRequired>
                                    <FormLabel htmlFor="phone">Phone number</FormLabel>
                                    <InputGroup alignItems={'center'}>
                                        <InputLeftElement pointerEvents='none'>
                                            <PhoneIcon color='gray.300' pos={'relative'} top={'5px'}/>
                                        </InputLeftElement>
                                        <Input id="phone" type="tel" name="phone" style={{paddingLeft: '2rem'}}/>
                                    </InputGroup>
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor="comment">Comment</FormLabel>
                                    <Textarea id="comment" name="comment" background={'#fff'}/>
                                </FormControl>
                                <HStack spacing="1">
                                    <Button type="submit" size="lg" bg={'#64bcb8'} color={'#fff'}
                                            _hover={{bg: '#329490', color: '#fff'}}
                                            fontWeight="bold" mt="2">
                                        Submit
                                    </Button>
                                </HStack>
                            </Stack>
                        </form>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
}

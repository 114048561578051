import React from "react";
import {Heading, Stack, Text} from "@chakra-ui/react";

export const BlogPost4 = () => {
    return (
        <Stack spacing={3}>
            <Text>
                Adopting eco-friendly tree care practices is not just beneficial for your trees but also for the
                environment at large. This blog post will explore how sustainable tree care practices can lead to
                healthier trees and a greener planet.
            </Text>
            <Heading as="h3">Reduces Chemical Use</Heading>
            <Text>
                Eco-friendly tree care minimizes the use of chemical fertilizers and pesticides, instead favoring
                organic methods that are less harmful to the environment and beneficial insects.
            </Text>
            <Heading as="h3">Conserves Water</Heading>
            <Text>
                Sustainable practices include efficient watering techniques that reduce waste, such as drip irrigation,
                which delivers water directly to the tree's root zone.
            </Text>
            <Heading as="h3">Promotes Biodiversity</Heading>
            <Text>
                By maintaining healthy trees and using native species in landscaping, eco-friendly tree care supports
                local wildlife and promotes biodiversity.
            </Text>
            <Heading as="h3">Improves Soil Health</Heading>
            <Text>
                Sustainable practices enhance soil health through organic mulching and avoiding soil compaction,
                creating a healthier environment for trees to grow.
            </Text>
            <Text>
                Eco-friendly tree care is a win-win for both your landscape and the environment. Simi Valley Tree
                Service Pros is committed to sustainable tree care practices that support the health of your trees and
                the planet. Contact us to learn more about our eco-friendly services.
            </Text>
        </Stack>
    )
}
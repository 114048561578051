import React from "react";
import {Heading, Stack, Text} from "@chakra-ui/react";

export const BlogPost3 = () => {
    return (
        <Stack spacing={3}>
            <Text>
                Tree diseases can pose significant threats to the urban forest in Simi Valley, affecting both the
                aesthetic and ecological value of the landscape. This blog post will guide you through identifying,
                treating, and preventing common tree diseases in the area.
            </Text>
            <Heading as="h3">Identifying Tree Diseases</Heading>
            <Text>
                Early detection is crucial. Look out for signs like discolored leaves, unusual growths, or premature
                leaf drop. If you notice any of these symptoms, it's essential to act quickly.
            </Text>
            <Heading as="h3">Effective Treatment Options</Heading>
            <Text>
                Treatment varies depending on the disease. It may involve pruning infected areas, applying fungicides,
                or, in severe cases, removing the tree to prevent further spread. Always consult with a professional
                arborist for accurate diagnosis and treatment plans.
            </Text>
            <Heading as="h3">Preventing Future Outbreaks</Heading>
            <Text>
                Prevention is key to maintaining healthy trees. Ensure proper planting practices, adequate watering, and
                regular maintenance. Additionally, keep your landscape clean of debris and fallen leaves that can harbor
                pathogens.
            </Text>
            <Text>
                Managing tree diseases is a critical aspect of tree care in Simi Valley. For expert assistance in
                identifying, treating, and preventing tree diseases, reach out to Simi Valley Tree Service Pros. Our
                team is dedicated to keeping your trees healthy and your landscape beautiful.
            </Text>
        </Stack>

    )
}
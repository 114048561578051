import React from "react";
import simiValleyMeta from '../assets/images/johnsoncitymeta.jpg'
import {
    Box,
    Button,
    Container,
    Flex,
    Grid,
    Heading,
    Image,
    Link,
    ListItem,
    Stack,
    Text,
    UnorderedList
} from "@chakra-ui/react";
import {BiConversation, BiCut} from "react-icons/bi";
import {GiChoppedSkull, GiLandMine, GiStumpRegrowth, GiTreeBranch, GiTreeDoor, GiTreeRoots} from "react-icons/gi";
import {FaConciergeBell, FaRegHandScissors, FaStar} from "react-icons/fa";
import {MdEmergencyShare, MdHealthAndSafety, MdOutlineEmergency, MdOutlineNaturePeople} from "react-icons/md";
import {AiOutlineScissor} from "react-icons/ai";
import treeImg3 from '../assets/images/overlandpark4.webp'
import {faqs} from "./faqs";
import {RiCustomerService2Line} from "react-icons/ri";
import {PiClockCountdownFill} from "react-icons/pi";

// const phoneNumber = '(423) 840-8746';
// const telLink = 'tel:4238408746';
// const phoneNumberWithDashes = '423-840-8746';

export const SiteData = {
    "hoursOfOperation": "Monday to Friday: 8 AM - 6 PM, Saturday: 9 AM - 4 PM, Closed on Sundays",
    "city": "Simi Valley, CA",
    "phoneNumber": "805-626-3370",
    "phoneNumberWithDashes": "805-626-3370",
    "telLink": "tel:805-626-3370",
    "buttonCtaText": () => <Flex>Looking for Premier Tree Care? Schedule Your&nbsp;<Text textDecoration={'underline'}
                                                                                         display={'inline'}>Free
        Consultation Today!</Text></Flex>,
    "keywords": "Tree Care, services, professional, affordable, experienced, pruning, Simi Valley, CA.",
    "footerText": "Simi Valley Tree Service Pros, All rights reserved.",
    "ogImage": simiValleyMeta,
    "navLinks": [
        {
            "title": "Home",
            "href": "/"
        },
        {
            "title": "Services",
            "href": "/services"
        },
        {
            "title": "Blog",
            "href": "/blog"
        },
        {
            "title": "About",
            "href": "/about"
        },
        {
            "title": "Contact Us",
            "href": "/contact"
        }
    ],
    "signupForm": {
        "intro": () => <Text>Welcome to Simi Valley Tree Service Pros, the leading tree service provider in Simi Valley,
            CA. Our team of certified arborists and tree care professionals are dedicated to bringing expert tree care
            to your doorstep. For exceptional tree services, reach out to us at <Link href={'tel:805-626-3370'}
                                                                                      textDecoration={'underline'}>805-626-3370</Link> today!</Text>,
        "servicesTitle": "Our Services:"
    },
    "homepage": {
        "url": "https://www.simivalleytreeservicepros.com",
        "metaData": {
            "title": "Simi Valley Tree Service Pros: Expert Tree Care | Call 805-626-3370",
            "description": "Simi Valley Tree Service Pros offers expert tree care and maintenance. Contact us today for professional tree services in Simi Valley, CA."
        },
        "h1": "Simi Valley Tree Service Pros - Exceptional Tree Care in Simi Valley, CA",
        "subtitle": "Professional Tree Service in Simi Valley, CA - Pruning, Removal, Health Consulting",
        "servicesTitle": "Why Simi Valley Chooses Our Tree Services",
        "values": [
            {
                id: 1,
                "number": "Over 5000",
                "name": "Trees Serviced",
                "icon": GiTreeBranch
            },
            {
                id: 2,
                number: "98%",
                name: "Customer Satisfaction Rate",
                icon: RiCustomerService2Line,
            },
            {
                id: 3,
                "number": <Flex my={3}><FaStar fontSize={'1.9rem'}/><FaStar fontSize={'1.9rem'}/><FaStar
                    fontSize={'1.9rem'}/><FaStar fontSize={'1.9rem'}/><FaStar fontSize={'1.9rem'}/></Flex>,
                "name": "Top-Rated Professionals",
                "icon": "FaUserTie"
            },
            {
                id: 4,
                number: "Prompt",
                name: "Emergency Response Time",
                icon: PiClockCountdownFill,
            },
        ],
        "services": [
            {
                "id": '0',
                "text": () => <Text><b>Expert Arborists:</b> Our certified arborists possess in-depth knowledge of Simi
                    Valley's unique tree species and environmental conditions, ensuring specialized care for your trees.</Text>
            },
            {
                "id": '1',
                "text": () => <Text><b>Comprehensive Tree Services:</b> From precision tree pruning to safe tree removal
                    and stump grinding, we cover all aspects of tree care to maintain your landscape's beauty and
                    health.</Text>
            },
            {
                "id": '2',
                "text": () => <Text><b>Affordable Solutions:</b> We believe in providing high-quality tree services at
                    competitive prices without compromising on the quality of our work.</Text>
            },
            {
                "id": '3',
                "text": () => <Text><b>Commitment to Excellence:</b> Our dedication to customer satisfaction and
                    environmental stewardship sets us apart in the Simi Valley community.</Text>
            }
        ],
        content: [
            <>

                <Heading as="h2" size={'md'} pb='2'>Simi Valley Tree Service Pros: Your Premier Tree Care
                    Experts</Heading>
                <Text>
                    Introducing Simi Valley Tree Service Pros, your ultimate partner for exceptional tree care services
                    in Simi Valley, California. Our dedicated team offers a wide array of <a
                    href={'https://www.cranefamilytree.com/'} target={"_blank"} rel={"noreferrer"}>tree service</a>,
                    meticulously
                    tailored to meet the specific needs of Simi Valley's homes and businesses. With our commitment to
                    quality, safety, and eco-friendliness, we aim to preserve and enhance the natural splendor of Simi
                    Valley's landscapes.
                </Text>

                <Heading as="h2" size={'md'} pb='2'>Comprehensive Tree Services in Simi Valley</Heading>
                <Text>
                    At Simi Valley Tree Service Pros, we provide a variety of tree care solutions, including detailed
                    <a href={"https://www.hounslowtreesurgeons.com/"} target={'_blank'} rel={'noreferrer'}>tree
                        trimming</a>, efficient stump grinding, and extensive tree health maintenance. Our experienced
                    arborists are dedicated to improving the health and aesthetics of your trees, ensuring the safety
                    and visual appeal of your property. Leveraging advanced tools and techniques, we deliver effective
                    and efficient tree services tailored to your needs.
                </Text>

                <Stack
                    direction={{
                        base: 'column',
                        lg: 'row',
                    }}
                    spacing={{
                        base: '12',
                        lg: '16',
                    }}
                    flex="1"
                >
                    <Box as="main" role="main" width="full" bg="bg.accent.default">
                        <Stack spacing={3}>
                            <Heading as="h2" size={'md'} pb='2'>Customized Tree Services for Every Requirement</Heading>
                            <Text>
                                Our tree services at Simi Valley Tree Service Pros are designed to comprehensively
                                address all aspects of tree care:
                            </Text>
                            <UnorderedList>
                                <ListItem>Tree Trimming: Enhancing tree health and aesthetics with precise trimming and
                                    pruning.</ListItem>
                                <ListItem>Stump Grinding: Removing stumps to augment the functionality and appearance of
                                    your landscape.</ListItem>
                                <ListItem>Tree Maintenance: Offering a complete range of tree health services for
                                    continuous care.</ListItem>
                                <ListItem>Tree Planting and Transplanting: Ensuring successful tree planting and
                                    transplanting for enduring vitality.</ListItem>
                                <ListItem>Tree Health Consulting: Providing expert guidance from certified arborists for
                                    informed tree care decisions.</ListItem>
                                <ListItem>Emergency Tree Services: Delivering swift responses to urgent tree care
                                    situations across Simi Valley.</ListItem>
                                <ListItem>Arborist Consultations: Offering professional insights into tree health, risk
                                    management, and eco-friendly practices.</ListItem>
                                <ListItem>Land Clearing: Conducting efficient and environmentally responsible land
                                    clearing for various projects.</ListItem>
                            </UnorderedList>
                        </Stack>
                    </Box>
                    <Box
                        as="aside"
                        role="complementary"
                        bg="bg.accent.default"
                        width={{
                            base: 'full',
                            lg: 'xl',
                        }}
                        alignSelf="center"
                        position={{
                            base: 'unset',
                        }}
                        top="36"
                    >
                        <Stack spacing={6}>
                            <Image src={treeImg3} alt={`tree removal simi valley ca`}/>
                        </Stack>
                    </Box>
                </Stack>

                <Heading as="h2" size={'md'} pb='2'>Innovative Tree Care Practices</Heading>
                <Text>
                    We take pride in utilizing the latest, most effective tree care practices at Simi Valley Tree
                    Service Pros. Our innovative approach ensures more efficient, safe, and environmentally friendly
                    tree care services. Keeping abreast with the latest in arboriculture, we guarantee that your trees
                    receive unparalleled care.
                </Text>

                <Heading as="h2" size={'md'} pb='2'>Dedication to Safety and Environmental Stewardship</Heading>
                <Text>
                    Safety and sustainability are at the core of everything we do at Simi Valley Tree Service Pros. We
                    adhere to stringent safety standards to protect our clients, their property, and our team during all
                    tree service operations. Committed to environmental stewardship, we strive to reduce our ecological
                    footprint while delivering outstanding tree care.
                </Text>

                <Heading as="h2" size={'md'} pb='2'>Local Expertise for Simi Valley’s Unique Environment</Heading>
                <Text>
                    Our extensive experience in Simi Valley equips us with a profound understanding of the local tree
                    species, climate, and environmental challenges. This local expertise enables us to provide
                    customized tree care solutions that perfectly match the needs of Simi Valley's diverse tree
                    population.
                </Text>

                <Heading as="h2" size={'md'} pb='2'>Why Choose Simi Valley Tree Service Pros?</Heading>
                <Text>
                    Opting for Simi Valley Tree Service Pros means choosing a team that values extensive knowledge in
                    tree care, adheres to eco-friendly practices, delivers timely and reliable service, tailors
                    solutions to meet your specific needs, and offers competitive pricing for top-notch tree services.
                </Text>
                <Text>
                    We're proud to be the foremost tree service provider in Simi Valley, dedicated to your satisfaction
                    and the wellbeing of your trees.
                </Text>

                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Fostering Long-Term Relationships With Our Clients</Heading>
                    Building lasting relationships with our clients is fundamental to us. We know that trust is built on
                    consistent, high-quality service. We aim to surpass your expectations at every turn, aspiring to be
                    your preferred tree service provider for all future needs.
                </Text>

                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Get in Touch With Simi Valley Tree Service Pros</Heading>
                    Ready for exceptional tree care in Simi Valley, CA? <Link href={"tel:805-626-3370"}>Call us at
                    805-626-3370</Link> for a personalized quote and discover our difference. Our team is eager to
                    address all your tree care needs with expertise and commitment.
                </Text>

                {/* Example button, assuming Chakra UI usage */}
                <Button as={'a'} href={'tel:805-626-3370'} bg={'#17b644'} fontSize={{base: '20px', md: '23px'}}
                        color={'white'} fontWeight={'bold'}
                        _hover={{bg: '#06c03b', color: 'white', cursor: 'pointer'}}
                        p={'16px 30px'} borderRadius={0} mt={'2rem'}
                        lineHeight={'26px'}
                        minH={'66px'}
                        width={'100%'}
                        maxW={'400px'}><i className="fa fa-phone" aria-hidden="true"/>&nbsp;&nbsp;Call for Immediate
                    Assistance</Button>
            </>
        ],
        additionalContent: <>
            <Container maxW="container.xl">
                <Box as="section" pt={10}>
                    <Text mb={4}>
                        Nestled in the vibrant landscapes of California, Simi Valley is home to Simi Valley Tree Service
                        Pros, a dedicated team passionate about tree health and beauty. We are more than a service
                        provider; we are stewards of the environment and partners in crafting your ideal outdoor space.
                        Our commitment extends beyond mere maintenance to encompass the wellbeing of your trees and the
                        enhancement of your property's natural charm.
                    </Text>
                </Box>
                <Box as="section" pt={10}>
                    <Heading as="h2" size="lg" mb={4}>
                        Our Pledge of Excellence
                    </Heading>
                    <Text mb={4}>
                        At Simi Valley Tree Service Pros, our mission is to offer unmatched tree care services to the
                        Simi Valley community. Armed with a team of certified arborists and tree care experts, we are
                        prepared to meet any challenge your trees might face. From the sprawling oak to the delicate
                        cherry blossom, our expertise ensures that every tree receives the personalized care it needs to
                        thrive.
                    </Text>
                </Box>
                <Box as="section" pt={10}>
                    <Heading as="h2" size="lg" mb={4}>
                        Wide-Ranging Tree Services
                    </Heading>
                    <Text mb={4}>
                        Our services are designed to cater to the unique requirements of your trees and landscape:
                    </Text>
                    <UnorderedList mb={4}>
                        <ListItem>
                            <strong>Tree Removal in Simi Valley, CA:</strong> Whether due to end-of-life or hazard
                            potential, we perform tree removals with the utmost efficiency and safety, minimizing impact
                            on your surroundings.
                        </ListItem>
                        <ListItem>
                            <strong>Tree Trimming in Simi Valley, CA:</strong> Essential for tree health and aesthetics,
                            our trimming services blend art with science to harmonize your outdoor area.
                        </ListItem>
                        <ListItem>
                            <strong>Stump Grinding and Removal:</strong> We eliminate unsightly stumps, clearing the way
                            for a pristine and safe landscape.
                        </ListItem>
                        <ListItem>
                            <strong>Emergency Tree Services:</strong> Quick and reliable responses to tree-related
                            emergencies protect your safety and property integrity.
                        </ListItem>
                        <ListItem>
                            <strong>Tree Health and Maintenance:</strong> We go beyond the basics, offering deep root
                            fertilization, disease management, and preventive care for long, healthy tree lives.
                        </ListItem>
                    </UnorderedList>
                </Box>
                <Box as="section" pt={10}>
                    <Heading as="h2" size="lg" mb={4}>
                        A Holistic Approach to Tree Care
                    </Heading>
                    <Text mb={4}>
                        Our philosophy at Simi Valley Tree Service Pros is holistic and eco-conscious. We prioritize:
                    </Text>
                    <UnorderedList mb={4}>
                        <ListItem>
                            <strong>Safety First:</strong> Adhering to the highest safety standards to protect both our
                            team and your property.
                        </ListItem>
                        <ListItem>
                            <strong>Continuous Learning:</strong> Keeping abreast of the latest in arboriculture to
                            ensure we deliver superior service.
                        </ListItem>
                        <ListItem>
                            <strong>Client-Centered Service:</strong> Your satisfaction drives us. We listen, advise,
                            and deliver to meet and exceed your expectations.
                        </ListItem>
                    </UnorderedList>
                </Box>
                <Box as="section" pt={10}>
                    <Heading as="h2" size="lg" mb={4}>
                        Why Simi Valley Chooses Us
                    </Heading>
                    <Text mb={4}>
                        Our reputation in Simi Valley is built on:
                    </Text>
                    <UnorderedList mb={4}>
                        <ListItem>
                            <strong>Experience and Expertise:</strong> A profound understanding of tree care, honed over
                            years of service and a team of certified professionals.
                        </ListItem>
                        <ListItem>
                            <strong>Reliability and Quality:</strong> Known for our punctuality, thoroughness, and
                            commitment to excellence.
                        </ListItem>
                        <ListItem>
                            <strong>Eco-Friendly Practices:</strong> A deep respect for nature underpins our
                            eco-conscious operations, benefiting both the environment and the community.
                        </ListItem>
                        <ListItem>
                            <strong>Value-Oriented Pricing:</strong> High-quality service at competitive rates, ensuring
                            value without compromise.
                        </ListItem>
                    </UnorderedList>
                </Box>
                <Box as="section" pt={10}>
                    <Heading as="h2" size="lg" mb={4}>
                        Enhancing Your Outdoor Space Awaits
                    </Heading>
                    <Text mb={4}>
                        Ready to transform your trees and outdoor area? Reach out to Simi Valley Tree Service Pros
                        today. Our team is standing by to provide expert guidance, service, and a no-obligation quote.
                        Let’s cultivate the beauty of Simi Valley together.
                    </Text>
                    <Button colorScheme="green" size="md" mb={4} as={'a'} href={"tel:805-626-3370"}>
                        Contact Us
                    </Button>
                </Box>
                <Box as="section">
                    <Heading as="h2" size="lg" mb={4}>
                        Our Roots in Simi Valley
                    </Heading>
                    <Text mb={4}>
                        Simi Valley Tree Service Pros began with a simple mission: to preserve and care for the trees of
                        Simi Valley, a city known for its stunning natural landscapes and rich biodiversity. What
                        started as a small, family-run operation has grown into a leading tree service company, grounded
                        in the principles of integrity, stewardship, and community engagement. Our journey is a
                        testament to our love for nature and commitment to enhancing the green spaces of our beloved
                        city.
                    </Text>
                </Box>

                {/* Our Team Section */}
                <Box as="section">
                    <Heading as="h2" size="lg" mb={4}>
                        The Heart of Our Operations: Our Team
                    </Heading>
                    <Text mb={4}>
                        At Simi Valley Tree Service Pros, our strength lies in our diverse team of tree care experts.
                        From certified arborists to seasoned tree surgeons, each member brings a wealth of experience
                        and a shared passion for preserving the environment. United by our dedication to excellence, we
                        work collaboratively to deliver unparalleled tree care services, ensuring the health and beauty
                        of Simi Valley's urban forest.
                    </Text>
                </Box>

                {/* Our Vision Section */}
                <Box as="section">
                    <Heading as="h2" size="lg" mb={4}>
                        A Green Future: Our Vision
                    </Heading>
                    <Text mb={4}>
                        Our vision is a community where every tree is nurtured, every landscape flourishes, and every
                        client is delighted. Through innovation, sustainability, and continuous learning, we aim to set
                        the standard for tree care in Simi Valley and beyond. We envision a future where our work not
                        only enhances the aesthetic of our city but also contributes to the wellbeing of the ecosystem
                        and its inhabitants.
                    </Text>
                </Box>
                <Box as="section" pt={10}>
                    <Heading as="h2" size="lg" mb={4}>
                        Frequently Asked Questions (FAQs)
                    </Heading>
                    <Grid templateColumns={{base: "repeat(1, 1fr)", md: "repeat(2, 1fr)"}} gap={6}>
                        {faqs.map(({question, answer}, index) => (
                            <Box
                                p={5}
                                // shadow="md"
                                borderWidth="1px"
                                borderRadius="lg"
                                key={index}
                            >
                                <Heading fontSize="xl">{question}</Heading>
                                <Text mt={4}>{answer}</Text>
                            </Box>
                        ))}
                    </Grid>
                </Box>

                {/* Closing Call to Action */}
                <Box as="section" pt={10}>
                    <Heading as="h2" size="lg" mb={4}>
                        Ready to Transform Your Landscape?
                    </Heading>
                    <Text mb={4}>
                        Whether you're looking to enhance the health of your trees, need urgent tree care, or seek
                        expert advice on tree maintenance, Simi Valley Tree Service Pros is here to help. Contact us
                        today to schedule a consultation and take the first step towards a greener, more beautiful Simi
                        Valley.
                    </Text>
                    <Button colorScheme="green" size="md" mb={4} as={'a'} href={"tel:805-626-3370"}>
                        Reach Out Now
                    </Button>
                </Box>
            </Container>
        </>,
        contactSection: <>
            <Container maxW="container.xl">
                <Container maxW="container.xl">
                    <Box as="section">
                        <Heading as="h2" size="lg" mb={4}>
                            Contact Us and Consultation
                        </Heading>
                        <Text mb={4}>
                            At Simi Valley Tree Service Pros, your landscape's well-being is our top priority. If you
                            have any tree care needs, questions about our services, or want advice on the best practices
                            for tree maintenance, we're here for you. Reach out to us today to schedule a personalized
                            consultation or to learn more about our comprehensive tree care solutions designed
                            specifically for Simi Valley residents.
                        </Text>
                        <Text mb={4}>
                            Our expert consultation process is tailored to understand your unique needs, providing the
                            most effective solutions. During the consultation, one of our tree care specialists will
                            assess your property, identify any issues, and offer tailored recommendations. We value open
                            and honest communication, ensuring you receive all the necessary information to make
                            informed decisions about your tree care.
                        </Text>
                        <Button colorScheme="green" size="md" mb={4} as={'a'} href="tel:805-626-3370">
                            Schedule a Consultation
                        </Button>
                    </Box>
                </Container>
                <Text fontSize="lg" textAlign="center" my={5}>
                    For premier tree services in Simi Valley, CA, connect with Simi Valley Tree Service Pros. Call <Link
                    href="tel:805-626-3370">805-626-3370</Link> or fill out our <Link href="/contact">online
                    form</Link> for queries. Your trusted partner for all tree care needs!
                </Text>
            </Container>
        </>,
        footerHeading: 'Contact Us for Tree Service in Simi Valley, CA',
        footerText: () => (
            <>
                For premier tree services in Simi Valley, CA, connect with Simi Valley Tree Service Pros today. Call or
                fill out our <Link href={'/contact'}>online contact form</Link> for inquiries. We are your trusted
                experts for all tree care needs in Simi Valley!
            </>
        ),
        footerText2: 'Expert Tree Service in Simi Valley, CA - Our Commitment to Your Trees\' Health and Beauty',
        servicesHeader: 'Explore Our Tree Services in Simi Valley, CA',
        servicesSubtitle: () => (
            <Text color="muted" fontSize={{base: 'lg', md: 'xl'}}>
                Discover our <Link href={'/services'}>comprehensive tree services</Link> in Simi Valley, CA. From
                emergency tree removal to routine tree health maintenance, Simi Valley Tree Service Pros is here for
                you.
            </Text>
        ),
        featuredServices: [
            {
                name: 'Tree Trimming',
                description: 'Our expert tree trimming and pruning services are designed to maintain the health and aesthetics of your trees, enhancing the overall beauty of your outdoor spaces.',
                icon: BiCut,
                path: '/tree-trimming-simi-valley/',
                linkTitle: 'Tree Trimming'
            },
            {
                name: 'Tree Removal',
                description: 'We provide safe and efficient tree removal services for hazardous or unwanted trees, ensuring the safety and aesthetic appeal of your property.',
                icon: GiChoppedSkull,
                path: '/tree-removal-simi-valley/',
                linkTitle: 'Tree Removal'
            },
            {
                name: 'Stump Grinding',
                description: 'Our team specializes in stump grinding and removal, aiming to safely and efficiently eliminate unwanted stumps from your yard, thereby improving the usability of your landscape.',
                icon: GiTreeRoots,
                path: '/stump-grinding-simi-valley/',
                linkTitle: 'Stump Grinding'
            },
            {
                name: 'Tree Planting and Transplanting',
                description: 'Our tree planting and transplanting service ensures the optimal care and handling of your trees during transit, ideal for relocating trees without causing them distress.',
                icon: GiTreeDoor,
                path: '/tree-planting-simi-valley/',
                linkTitle: 'Tree Planting and Transplanting'
            },
            {
                name: 'Tree Health Consulting',
                description: 'Seek professional advice on your trees? Our certified arborist consulting service provides you with the necessary insights and recommendations to make informed decisions regarding your tree care.',
                icon: FaConciergeBell,
                path: '/tree-health-consulting-simi-valley/',
                linkTitle: 'Tree Health Consulting'
            },
            {
                name: 'Emergency Tree Services',
                description: 'We offer prompt and reliable emergency tree services, prepared to address urgent tree care needs caused by storms, damage, or other unforeseen events efficiently.',
                icon: MdOutlineEmergency,
                path: '/emergency-tree-services-simi-valley/',
                linkTitle: 'Emergency Tree Services'
            },
            {
                name: 'Arborist Consultations',
                description: 'Gain expert insights and solutions for your trees with our professional arborist consultations, focusing on tree health, risk assessment, and sustainable management strategies.',
                icon: MdOutlineNaturePeople,
                path: '/arborist-consultations-simi-valley/',
                linkTitle: 'Arborist Consultations'
            },
            {
                name: 'Land Clearing',
                description: 'Our extensive land clearing services prepare your property for construction, landscaping, or agricultural projects, ensuring an efficient and eco-friendly approach.',
                icon: FaRegHandScissors,
                path: '/land-clearing-simi-valley/',
                linkTitle: 'Land Clearing'
            },
        ]
    },
    careers: {
        "url": "https://www.simivalleytreeservicepros.com/careers/",
        "metaData": {
            "title": "Join Our Team - Career Opportunities at Simi Valley Tree Service Pros, Simi Valley, CA",
            "description": "Explore career opportunities with Simi Valley Tree Service Pros. Join our dedicated tree care team in Simi Valley, CA. We're seeking passionate individuals committed to excellence in tree care."
        },
        "h1": "We're Hiring - Become a Part of Simi Valley Tree Service Pros",
        "h2": [
            "Why Join Simi Valley Tree Service Pros?",
            "Current Openings for Tree Care Specialists",
            "Our Commitment to Employee Development and Satisfaction"
        ],
        "headingText": {
            "whyJoin": "Embark on a career where your passion for trees and the environment is deeply valued. Become a part of a committed team delivering top-tier tree care services across Simi Valley, CA.",
            "openings": "Explore our current job listings for arborists, tree climbers, and grounds maintenance staff. Find your fit in a role that matches your skill set and passion for tree care.",
            "commitment": "At Simi Valley Tree Service Pros, we are dedicated to fostering our team's skills through continuous training and a supportive work environment. Your professional growth is key to our mutual success."
        },
        "content": [
            <Text>Welcome to the Careers page at <Link href={'/'}>Simi Valley Tree Service Pros</Link>, where you can
                start or advance your career in arboriculture and tree care. Join our family of professionals committed
                to excellence in tree services and make a significant impact in Simi Valley, CA.</Text>,
            <Text>At <Link href={'/'}>Simi Valley Tree Service Pros</Link>, we believe our strength lies in our team. We
                seek individuals who share our dedication to outstanding tree care. Regardless if you are a seasoned
                arborist or new to the field, we provide an environment where your talents are recognized and
                cultivated.</Text>,
            <Text>Our team enjoys a culture of personal and professional development. We offer extensive training,
                competitive salaries, and the chance to work with the latest tree care technology. Our aim is to ensure
                a secure, rewarding, and fulfilling workplace for every team member.</Text>,
            <Text>As a vital part of the Simi Valley community, we take pride in offering our services with integrity
                and professionalism. We extend our commitment to excellence to our employees, providing a positive work
                setting, cohesive teamwork, and career advancement opportunities.</Text>,
            <Text>Ready to become a leading tree service professional in Simi Valley, CA? Explore our current job
                openings and discover where you fit in our expanding team. From expert tree climbers to customer service
                professionals, there's a spot for you at Simi Valley Tree Service Pros.</Text>,
            <Text><a href='https://www.simivalleytreeservicepros.com/careers/'>Find your next career
                opportunity</a> with us. Join a team committed to nurturing and enhancing the urban forest. At <Link
                href={'/'}>Simi Valley Tree Service Pros</Link>, we're more than a company; we're a community. Apply
                today and advance your career in tree care.</Text>,
        ]
    },
    about: {
        url: "https://www.simivalleytreeservicepros.com/about/",
        metaData: {
            title: "Expert Tree Care Services in Simi Valley | Trusted & Professional Tree Company | Call Us Today",
            description: "Whether it's tree pruning or emergency tree removal, our skilled team is ready to meet all your arboricultural needs.",
        },
        h1: "Tree Services in Simi Valley, CA",
        h2: [
            "Emergency Tree Services: Ready 24/7 for all urgent tree care and removal situations.",
            "Experienced Arborists: Our certified arborists possess the skills and tools needed for top-quality tree care.",
            "Comprehensive Tree Care: We offer everything from tree health assessments to stump grinding.",
            "Eco-Friendly Practices: We emphasize the health of your trees and the planet in every service we render.",
            "Competitive Pricing: Accessible, high-quality tree care services at competitive rates."
        ],
        headingText: {
            commitment: "Committed to providing outstanding tree care, we ensure the health and beauty of Simi Valley's urban forest.",
            team: "Our team at Simi Valley Tree Service Pros includes dedicated arborists and tree care experts, all striving for excellence in every task.",
            services: "Simi Valley Tree Service Pros offers a broad spectrum of tree care services. From regular maintenance and pruning to emergency removals and disease treatment, we cater to all your tree care necessities. Utilizing advanced methods and equipment, we guarantee the health and safety of your trees and property. Whether for a home garden or a commercial area, our team delivers professional, efficient, and environmentally responsible services."
        },
        services: [
            {
                id: "0",
                title: "Tree Pruning & Trimming",
                href: "/tree-pruning",
                icon: AiOutlineScissor,
                description: (
                    <Text>
                        Meticulous pruning and trimming to keep your trees healthy and aesthetically pleasing, carried
                        out by our expert arborists.
                    </Text>
                ),
            },
            {
                id: "1",
                title: "Tree Removal",
                href: "/tree-removal",
                icon: GiChoppedSkull,
                description: (
                    <Text>
                        Expert removal of dangerous or unwanted trees, ensuring the safety and beauty of your premises.
                    </Text>
                ),
            },
            {
                id: "2",
                title: "Stump Grinding & Removal",
                href: "/stump-grinding",
                icon: GiStumpRegrowth,
                description: (
                    <Text>
                        Thorough stump grinding and removal to beautify and make use of your landscape, ready for new
                        growth or design projects.
                    </Text>
                ),
            },
            {
                id: "3",
                title: "Emergency Tree Services",
                href: "/emergency-tree-services",
                icon: MdEmergencyShare,
                description: (
                    <Text>
                        Quick, efficient, and safe solutions for emergency tree situations caused by storms or
                        unexpected tree failures.
                    </Text>
                ),
            },
            {
                id: "4",
                title: "Tree Health Assessments",
                href: "/tree-health-assessments",
                icon: MdHealthAndSafety,
                description: (
                    <Text>
                        Detailed evaluations to identify and manage tree diseases and pests, promoting your trees'
                        health and longevity.
                    </Text>
                ),
            },
            {
                id: "5",
                title: "Arborist Consultations",
                href: "/arborist-consultations",
                icon: BiConversation,
                description: (
                    <Text>
                        In-depth consultations with our arborists to guide you on the best care, planting, and
                        maintenance practices for a vibrant and healthy landscape.
                    </Text>
                ),
            },
            {
                id: "6",
                title: "Land Clearing & Lot Preparation",
                href: "/land-clearing",
                icon: GiLandMine,
                description: (
                    <Text>
                        Effective clearing services for construction, landscaping, or development projects, focusing on
                        eco-conscious methods.
                    </Text>
                ),
            },
        ],
    },
    "testimonials": {
        "h1": "What Our Clients Say",
        "heading": "Hear directly from our satisfied clients and learn why Simi Valley Tree Service Pros is the preferred choice for tree care services in Simi Valley, CA.",
        "testimonials": [
            {
                "name": "John D.",
                "quote": "Simi Valley Tree Service Pros transformed my backyard with their expert tree pruning and removal services. Highly professional and efficient!"
            },
            {
                "name": "Emma S.",
                "quote": "The team at Simi Valley Tree Service Pros was knowledgeable and friendly. They provided invaluable advice on maintaining the health of my trees."
            },
            {
                "name": "Carlos R.",
                "quote": "After a storm, Simi Valley Tree Service Pros was quick to respond and safely removed a fallen tree from my property. Exceptional service!"
            }
        ]
    },
    "services": {
        "metaData": {
            "title": "Comprehensive Tree Care Services in Simi Valley, CA | Simi Valley Tree Service Pros",
            "description": "From tree pruning to emergency tree removal, our team at Simi Valley Tree Service Pros is dedicated to your landscape's health and beauty. Contact us at 805-626-3370."
        },
        "h1": "Expert Tree Services in Simi Valley, CA",
        "h2": "Tree Care Services We Offer",
        "subtext": "Simi Valley Tree Service Pros is committed to offering a wide range of tree care services to ensure your landscape remains vibrant and safe.",
        "footerHeading": "Ready to Transform Your Landscape?",
        "footerText2": () => <Text>Connect with us today at <Link href={'tel:805-626-3370'}>805-626-3370</Link> or fill
            out our online contact form for a free quote. Let Simi Valley Tree Service Pros take your landscape to the
            next level.</Text>,
        "footerText": () => <Text>At Simi Valley Tree Service Pros, we place your trees and landscape first. For
            top-tier tree care in Simi Valley, CA, we are your premier choice. Reach out today!</Text>,
        "services": [
            {
                "id": '0',
                "text": () => <Text><b>Tree Pruning & Trimming:</b> Enhance the health and appearance of your trees with
                    our expert pruning and trimming services, tailored to the unique needs of each species.</Text>
            },
            {
                "id": '1',
                "text": () => <Text><b>Stump Grinding & Removal:</b> Remove unsightly or hazardous stumps with our
                    efficient grinding and removal services, clearing the way for new growth or landscaping
                    projects.</Text>
            },
            {
                "id": '2',
                "text": () => <Text><b>Emergency Tree Removal:</b> Available to address urgent tree care needs, our team
                    provides swift and safe removal of hazardous trees to protect your property.</Text>
            },
            {
                "id": '3',
                "text": () => <Text><b>Tree Health Consulting:</b> Consult with our certified arborists to diagnose and
                    treat tree diseases, ensuring the long-term health of your green assets.</Text>
            }
        ],
        "whyh2": "Why Simi Valley Residents Trust Us for Tree Care",
        "whySubtext": () => <Text>Simi Valley Tree Service Pros has built a reputation for excellence in tree care,
            backed by years of experience and a commitment to customer satisfaction and environmental sustainability.
            Your landscape deserves the best, and we're here to deliver.</Text>,
        "whyServices": [
            {
                "id": '0',
                "text": () => <Text><b>Expertise You Can Rely On:</b> Our team's extensive training and knowledge ensure
                    that your trees are in capable hands.</Text>
            },
            {
                "id": '1',
                "text": () => <Text><b>Customer-Centric Approach:</b> We prioritize your needs and satisfaction,
                    providing customized solutions and open communication throughout every project.</Text>
            },
            {
                "id": '2',
                "text": () => <Text><b>Comprehensive Service Range:</b> No matter the size or scope of your tree care
                    needs, we have the skills and equipment to address them effectively.</Text>
            },
            {
                "id": '3',
                "text": () => <Text><b>Local Insights:</b> Our deep understanding of Simi Valley's ecosystem allows us
                    to provide specialized care that aligns with the area's unique environmental conditions.</Text>
            }
        ]
    },
    "contact": {
        "url": "https://www.simivalleytreeservicepros.com/contact/",
        "metaData": {
            "title": "Contact Simi Valley Tree Service Pros | Call 805-626-3370",
            "description": "Reach out to Simi Valley Tree Service Pros for exceptional tree care services in Simi Valley, CA. We're here to help with all your tree care needs."
        },
        "h1": "Contact Simi Valley Tree Service Pros",
        "subtitle": "We're dedicated to providing outstanding tree service to our community in Simi Valley, CA. Our friendly team is ready to assist with all your tree care inquiries.",
        "sectionHeading": "Why Choose Simi Valley Tree Service Pros?",
        "signUpDescription": "Interested in our services? Contact us online or call us directly. We'll get back to you as soon as possible to discuss how we can help.",
        "feedbackText": "We appreciate your feedback and questions. Your satisfaction is our top priority, and we're always here to ensure your tree care needs are met.",
        "closingText": () => <Text>Contact Simi Valley Tree Service Pros for reliable, professional, and sustainable
            tree care solutions. We look forward to serving you.</Text>,
        "valueProps": [
            {
                "title": "Expert Arborists",
                "description": "Our team of certified arborists brings expertise and passion to every project, ensuring the highest quality of care for your trees."
            },
            {
                "title": "Full Range of Tree Services",
                "description": "From routine maintenance to emergency removals, we offer comprehensive tree care services to meet all your needs."
            },
            {
                "title": "Advanced Equipment",
                "description": "We utilize the latest in tree care technology and equipment to provide efficient and effective service."
            },
            {
                "title": "Eco-Friendly Practices",
                "description": "Our commitment to sustainability means we employ environmentally responsible methods in all our services."
            },
            {
                "title": "Customer Satisfaction",
                "description": "Your satisfaction is our goal. We strive to exceed expectations with our quality service and professional integrity."
            }
        ]
    },
    "404": {
        "metaData": {
            "title": "Page Not Found - Simi Valley Tree Service Pros | Tree Service | 805-626-3370",
            "description": "Oops! The page you're looking for could not be found. Simi Valley Tree Service Pros offers professional tree care services. Contact us for reliable help."
        },
        "h1": "404 - Oh no, you found a page that's missing its roots.",
        "subtitle": "Unfortunately, the page you are looking for does not exist.",
        "p": "Whether you need tree trimming, stump removal, or any other tree care service, we've got you covered. Call us at 805-626-3370 for your tree care needs."
    },
    "blog": {
        "url": "https://www.simivalleytreeservicepros.com/blog/",
        "metaData": {
            "title": "Latest News and Tips from Simi Valley Tree Service Pros | Blog",
            "description": "Stay updated with the latest tree care tips, news, and insights from Simi Valley Tree Service Pros. Your go-to resource for all things trees in Simi Valley, CA."
        },
        "h1": "Simi Valley Tree Service Pros Blog",
        "h2": "Explore Our Latest Articles & Tips",
        "heading": "Our blog is a treasure trove of valuable tree care information, designed to help you understand and care for your trees better. Whether you're looking for advice on seasonal tree care, how-to guides, or the latest trends in arboriculture, you'll find it all here.",
        "posts": [
            {
                "id": "1",
                "title": "5 Essential Tree Care Tips for Simi Valley Homeowners",
                "metaTitle": "Tree Care Tips for Homeowners in Simi Valley, CA | Simi Valley Tree Service Pros Blog",
                "excerpt": "Discover the top tree care practices to keep your landscape vibrant and healthy throughout the year.",
                "slug": "essential-tree-care-tips-for-simi-valley-homeowners",
                "tags": ["tree care", "tips", "homeowners", "Simi Valley"],
                "metaDescription": "Learn the best tree care tips for Simi Valley homeowners to ensure your trees remain healthy and beautiful all year round."
            },
            {
                "id": "2",
                "title": "The Importance of Regular Tree Pruning",
                "metaTitle": "Why Regular Tree Pruning is Crucial for Tree Health | Simi Valley Tree Service Pros Blog",
                "excerpt": "Find out why regular tree pruning is not just about aesthetics but crucial for the health and safety of your trees.",
                "slug": "importance-of-regular-tree-pruning",
                "tags": ["tree pruning", "tree health", "safety"],
                "metaDescription": "Explore the benefits of regular tree pruning and how it contributes to the health, safety, and aesthetic appeal of your trees."
            },
            {
                "id": "3",
                "title": "How to Deal with Tree Diseases in Simi Valley",
                "metaTitle": "Managing Tree Diseases in Simi Valley, CA | Simi Valley Tree Service Pros Blog",
                "excerpt": "Learn how to identify, treat, and prevent common tree diseases in Simi Valley to keep your trees thriving.",
                "slug": "dealing-with-tree-diseases-in-simi-valley",
                "tags": ["tree diseases", "treatment", "prevention", "Simi Valley"],
                "metaDescription": "A comprehensive guide to identifying, treating, and preventing tree diseases in Simi Valley, CA, to ensure the longevity of your trees."
            },
            {
                "id": "4",
                "title": "The Benefits of Eco-Friendly Tree Care Practices",
                "metaTitle": "Eco-Friendly Tree Care Practices and Their Benefits | Simi Valley Tree Service Pros Blog",
                "excerpt": "Discover how adopting eco-friendly tree care practices can benefit not only your trees but the environment as well.",
                "slug": "eco-friendly-tree-care-practices",
                "tags": ["eco-friendly", "tree care", "environment"],
                "metaDescription": "Learn about the importance of eco-friendly tree care practices and how they contribute to a healthier environment and vibrant landscapes."
            }
        ]
    },
    "treePruning": {
        "url": 'https://www.simivalleytreeservicepros.com/tree-pruning',
        "metaData": {
            "title": 'Professional Tree Pruning in Simi Valley, CA | Call Us: 805-626-3370',
            "description": 'Enhance your landscape with expert tree pruning services in Simi Valley, CA. Simi Valley Tree Service Pros are here to help with all your tree care needs.'
        },
        "h1": 'Tree Pruning Services in Simi Valley, CA',
        "subtitle": 'In the heart of Simi Valley, trees stand as silent witnesses to the passing seasons, contributing to the beauty, air quality, and natural ambiance of our community. Proper tree care, including regular pruning, is essential for maintaining the health, safety, and aesthetic appeal of these vital natural resources. At Simi Valley Tree Service Pros, we specialize in professional tree pruning services designed to nurture your trees’ health, enhance their appearance, and ensure their longevity.',
        "imgAlt": 'tree pruning simi valley ca',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Expert Tree Pruning Services</Heading>
                <Text>We at Simi Valley Tree Service Pros are dedicated to offering exceptional tree pruning services
                    tailored to the specific needs of Simi Valley’s diverse tree population. Our services
                    include:</Text>
                <UnorderedList spacing={3}>
                    <ListItem><b>Health Pruning:</b> Targeting and removing dead, diseased, or pest-infested branches to
                        protect and enhance the tree’s overall well-being.</ListItem>
                    <ListItem><b>Structural Pruning:</b> Strengthening the tree's structure to withstand the challenges
                        of weather and time, reducing the risk of damage to property and people.</ListItem>
                    <ListItem><b>Aesthetic Pruning:</b> Shaping trees to highlight their natural beauty, complementing
                        your landscape’s design and enhancing curb appeal.</ListItem>
                    <ListItem><b>Safety Pruning:</b> Eliminating potential hazards by removing branches that threaten
                        structures, power lines, or pedestrian areas.</ListItem>
                </UnorderedList>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Why Trust Simi Valley Tree Service Pros?</Heading>
                <Text>Choosing Simi Valley Tree Service Pros for your tree pruning needs means entrusting your trees to
                    experts who care deeply about the health of your landscape and the safety of your community. Here’s
                    why we stand out:</Text>
                <UnorderedList spacing={3}>
                    <ListItem><b>Qualified Arborists:</b> Our team comprises certified arborists with extensive
                        knowledge and experience in tree care, ensuring the highest standards of service.</ListItem>
                    <ListItem><b>Commitment to Excellence:</b> We pride ourselves on delivering superior quality
                        services, with a focus on customer satisfaction and meticulous attention to detail.</ListItem>
                    <ListItem><b>Local Expertise:</b> Understanding the unique environmental conditions of Simi Valley
                        allows us to provide specialized care tailored to your trees’ specific needs.</ListItem>
                    <ListItem><b>Customer-Centric Service:</b> Our priority is your satisfaction. We strive to exceed
                        expectations through professional, friendly, and responsive service.</ListItem>
                </UnorderedList>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Schedule Your Tree Pruning Today</Heading>
                <Text>Don’t let your trees go unchecked. Contact Simi Valley Tree Service Pros at <Link
                    href={'tel:805-626-3370'}>805-626-3370</Link> today to schedule a comprehensive tree pruning
                    service. Enhance the health and beauty of your landscape with the help of Simi Valley’s tree care
                    experts.</Text>
            </Stack>
        </Box>
    },
    treeRemoval: {
        "url": "https://www.simivalleytreeservicepros.com/tree-removal",
        "metaData": {
            "title": "Safe and Efficient Tree Removal in Simi Valley, CA | Call Now",
            "description": "Need a tree removed? Our experts in Simi Valley provide safe, efficient tree removal services, ensuring your property's safety and aesthetic integrity."
        },
        "h1": "Professional Tree Removal Services in Simi Valley, CA",
        "subtitle": "Whether it’s due to disease, damage, or landscaping changes, our team offers safe and efficient tree removal services.",
        "body": <Box>
            <Text mb={4}>Removing a tree, especially one that has been part of your landscape for years, can be a
                daunting task. At Simi Valley Tree Service Pros, we approach each tree removal project with the utmost
                care and professionalism, ensuring the safety of your property and the well-being of the surrounding
                area.</Text>
            <Text mb={4}>Our removal process includes:</Text>
            <UnorderedList mb={4}>
                <ListItem>Comprehensive assessment and planning</ListItem>
                <ListItem>Safe removal with minimal impact</ListItem>
                <ListItem>Complete debris cleanup and disposal</ListItem>
            </UnorderedList>
            <Text mb={4}>If you're facing a tree removal need, let our experts guide you through the process. Call us
                at <Link color="teal.500" href="tel:805-626-3370">805-626-3370</Link> for assistance.</Text>
        </Box>
    },
    treeTrimming: {
        "url": "https://www.simivalleytreeservicepros.com/tree-trimming",
        "metaData": {
            "title": "Professional Tree Trimming in Simi Valley, CA | Enhance Your Landscape",
            "description": "Revitalize your landscape with our expert tree trimming services in Simi Valley. Our skilled arborists ensure your trees look their best year-round."
        },
        "h1": "Expert Tree Trimming Services in Simi Valley, CA",
        "subtitle": "Keep your trees healthy and your landscape beautiful with professional tree trimming services from Simi Valley Tree Service Pros.",
        "body": <Box>
            <Text mb={4}>At Simi Valley Tree Service Pros, we understand that proper tree trimming is crucial for the
                health and aesthetics of your trees. Our certified arborists use precision and care to enhance your
                tree's natural shape, promote healthy growth, and prevent potential hazards.</Text>
            <Text mb={4}>Our services include:</Text>
            <UnorderedList mb={4}>
                <ListItem>Thinning for light and air penetration</ListItem>
                <ListItem>Shaping for aesthetic appeal</ListItem>
                <ListItem>Deadwooding for health and safety</ListItem>
            </UnorderedList>
            <Text mb={4}>Let us help you maintain the vitality and beauty of your trees. Contact us today at <Link
                color="teal.500" href="tel:805-626-3370">805-626-3370</Link> for a free consultation.</Text>
        </Box>
    },
    stumpGrinding: {
        "url": "https://www.simivalleytreeservicepros.com/stump-grinding",
        "metaData": {
            "title": "Efficient Stump Grinding Services in Simi Valley, CA | Contact Us",
            "description": "Eliminate unsightly stumps with our stump grinding service in Simi Valley. Our team ensures a smooth, hazard-free landscape."
        },
        "h1": "Stump Grinding Services in Simi Valley, CA",
        "subtitle": "Turn problematic stumps into past memories with our efficient stump grinding services.",
        "body": <Box>
            <Text mb={4}>Stump grinding is an effective solution for removing the remnants of cut-down trees from your
                property. Simi Valley Tree Service Pros uses state-of-the-art equipment to grind stumps below the
                surface level, allowing for easy landscaping or replanting.</Text>
            <Text mb={4}>Benefits of our stump grinding service include:</Text>
            <UnorderedList mb={4}>
                <ListItem>Improvement in landscape aesthetics and usability</ListItem>
                <ListItem>Elimination of safety hazards and pest harborage</ListItem>
                <ListItem>Preparation of the area for new planting or construction</ListItem>
            </UnorderedList>
            <Text mb={4}>Don't let tree stumps take up valuable space on your property. Reach out to us at <Link
                color="teal.500" href="tel:805-626-3370">805-626-3370</Link> for a seamless stump removal
                experience.</Text>
        </Box>
    },
    treeHealthConsulting: {
        "url": "https://www.simivalleytreeservicepros.com/tree-health-consulting",
        "metaData": {
            "title": "Tree Health Consulting Services in Simi Valley, CA | Certified Arborists",
            "description": "Ensure the vitality of your trees with our tree health consulting services in Simi Valley. Get insights and care tips from certified arborists."
        },
        "h1": "Professional Tree Health Consulting in Simi Valley, CA",
        "subtitle": "Keep your trees thriving with expert health assessments and personalized care plans from our certified arborists.",
        "body": <Box>
            <Text mb={4}>Healthy trees are the backbone of any beautiful landscape. Our certified arborists at Simi
                Valley Tree Service Pros offer comprehensive tree health consulting services, including diagnostics,
                treatment plans, and maintenance advice to keep your trees in peak condition.</Text>
            <Text mb={4}>Our consulting services cover:</Text>
            <UnorderedList mb={4}>
                <ListItem>Disease and pest identification and management</ListItem>
                <ListItem>Soil analysis and nutrient management recommendations</ListItem>
                <ListItem>Pruning and care schedules tailored to each tree's needs</ListItem>
            </UnorderedList>
            <Text mb={4}>For expert guidance on maintaining the health and beauty of your trees, reach out to our
                knowledgeable team at <Link color="teal.500" href="tel:805-626-3370">805-626-3370</Link>.</Text>
        </Box>
    },
    landClearing: {
        "url": "https://www.simivalleytreeservicepros.com/land-clearing",
        "metaData": {
            "title": "Efficient Land Clearing Services in Simi Valley, CA | Prepare Your Property",
            "description": "Ready for construction or landscaping? Our land clearing services in Simi Valley prepare your property efficiently and responsibly."
        },
        "h1": "Land Clearing Services in Simi Valley, CA",
        "subtitle": "Professional land clearing to prepare your site for construction, landscaping, or agricultural use.",
        "body": <Box>
            <Text mb={4}>Whether you’re looking to build, landscape, or cultivate, land clearing by Simi Valley Tree
                Service Pros sets the foundation for a successful project. Our team uses the latest techniques and
                equipment to clear land quickly and with minimal environmental impact.</Text>
            <Text mb={4}>Our services include:</Text>
            <UnorderedList mb={4}>
                <ListItem>Removal of trees, shrubs, and underbrush</ListItem>
                <ListItem>Stump grinding and soil preparation</ListItem>
                <ListItem>Erosion control and site cleanup</ListItem>
            </UnorderedList>
            <Text mb={4}>For a clean slate to bring your vision to life, contact us at <Link color="teal.500"
                                                                                             href="tel:805-626-3370">805-626-3370</Link>.
                Let’s prepare your property together.</Text>
        </Box>
    },
    arboristConsulting: {
        "url": "https://www.simivalleytreeservicepros.com/arborist-consultations",
        "metaData": {
            "title": "Expert Arborist Consultations in Simi Valley, CA | Tree Care Solutions",
            "description": "Get personalized advice and solutions for your trees from our expert arborist consultations in Simi Valley. Enhance your tree care strategy today."
        },
        "h1": "Arborist Consultations in Simi Valley, CA",
        "subtitle": "Benefit from the deep knowledge and experience of our certified arborists to ensure the best care for your trees.",
        "body": <Box>
            <Text mb={4}>Arborist consultations at Simi Valley Tree Service Pros provide you with access to the
                expertise needed to tackle any tree care challenge. From health assessments to risk management and
                landscaping advice, our certified arborists are here to help you make informed decisions about your
                trees.</Text>
            <Text mb={4}>Consultation services include:</Text>
            <UnorderedList mb={4}>
                <ListItem>Comprehensive tree health evaluations</ListItem>
                <ListItem>Customized care plans and maintenance advice</ListItem>
                <ListItem>Identification and management of tree diseases and pests</ListItem>
            </UnorderedList>
            <Text mb={4}>To schedule a consultation and learn how to best care for your trees, contact us today at <Link
                color="teal.500" href="tel:805-626-3370">805-626-3370</Link>.</Text>
        </Box>
    },
    treePlanting: {
        "url": "https://www.simivalleytreeservicepros.com/tree-planting",
        "metaData": {
            "title": "Tree Planting & Transplanting Services in Simi Valley, CA | Expert Care",
            "description": "Grow your green space with our tree planting and transplanting services in Simi Valley. Offering expert advice and care for your new trees."
        },
        "h1": "Tree Planting and Transplanting Services in Simi Valley, CA",
        "subtitle": "Enhance your landscape and ecosystem with our professional tree planting and transplanting services.",
        "body": <Box>
            <Text mb={4}>Planting a tree is a commitment to the future. Simi Valley Tree Service Pros provides expert
                tree planting and transplanting services to ensure the healthy growth and integration of trees into your
                landscape.</Text>
            <Text mb={4}>Our services include:</Text>
            <UnorderedList mb={4}>
                <ListItem>Selection of appropriate tree species for your climate and soil</ListItem>
                <ListItem>Strategic placement to optimize tree health and property aesthetics</ListItem>
                <ListItem>Professional planting techniques for strong root establishment</ListItem>
            </UnorderedList>
            <Text mb={4}>Whether you're adding to your existing landscape or starting new, our team is here to support
                every step of the way. Contact us at <Link color="teal.500"
                                                           href="tel:805-626-3370">805-626-3370</Link> for a
                consultation.</Text>
        </Box>
    },
    emergencyTreeServices: {
        "url": "https://www.simivalleytreeservicepros.com/emergency-tree-services",
        "metaData": {
            "title": "24/7 Emergency Tree Services in Simi Valley, CA | Immediate Response",
            "description": "Facing a tree emergency? Our team provides rapid, reliable emergency tree services in Simi Valley to address hazards and damages promptly."
        },
        "h1": "Emergency Tree Services in Simi Valley, CA",
        "subtitle": "Quick, reliable response for tree emergencies to ensure your safety and property security.",
        "body": <Box>
            <Text mb={4}>Tree emergencies can happen without warning, posing immediate risks to safety and property.
                Simi Valley Tree Service Pros offers around-the-clock emergency tree services, ready to respond when you
                need us most.</Text>
            <Text mb={4}>Our emergency services include:</Text>
            <UnorderedList mb={4}>
                <ListItem>Storm damage cleanup and tree stabilization</ListItem>
                <ListItem>Rapid removal of fallen or hazardous trees</ListItem>
                <ListItem>Assessment and mitigation of potential tree-related dangers</ListItem>
            </UnorderedList>
            <Text mb={4}>In an emergency, don’t wait. Call us immediately at <Link color="teal.500"
                                                                                   href="tel:805-626-3370">805-626-3370</Link> for
                prompt, professional service.</Text>
        </Box>
    },
}